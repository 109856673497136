<template>
    <v-card class="mb-0" :width="width" outlined>
        <v-list-item class="pa-1 pt-2">
            <div>
                <files-icon
                    :fileType="file.extension.toLowerCase()"
                    :width="iconWidth"
                    :showBadge="false"
                    v-if="!showImage"
                ></files-icon>
                <v-img v-else max-width="70" :src="file.path"></v-img>
            </div>
            <v-list-item-content class="px-1">
                <v-row no-gutters>
                    <v-col cols="12">
                        <div :title="file.name" class="mb-0 text-wrap">
                            {{ file.name | truncate(20) }}
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-list-item-subtitle>
                            {{ file.extension.toUpperCase() }}
                            <span v-if="fileSizeFormat"
                                >({{ fileSizeFormat }})</span
                            >
                        </v-list-item-subtitle>
                    </v-col>
                </v-row>
            </v-list-item-content>
        </v-list-item>
        <v-card-actions>
            <v-btn
                rounded
                x-small
                outlined
                color="primary"
                @click="downLoadFile(file.path)"
            >
                <v-icon small>mdi-download</v-icon>
            </v-btn>
            <v-btn
                v-if="allowDelete"
                rounded
                :disabled="disabled"
                outlined
                @click="deleteFile"
                color="red"
                x-small
            >
                <v-icon small>mdi-delete-outline</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import filesIcon from "./filesIcon.vue";
export default {
    components: { filesIcon },
    props: {
        fileUrl: {
            type: String
        },
        file: {
            type: Object
        },
        width: {
            type: Number,
            default: 300
        },
        fileNameLength: {
            type: Number,
            default: 25
        },
        fileSizeFormat: {
            type: String,
            default: ""
        },
        iconWidth: {
            type: Number,
            default: 64
        },
        onDelete: {
            type: Function,
            required: false
        },
        allowDelete: {
            type: Boolean,
            default: true
        },
        showImage: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        downLoadFile(url) {
            window.open(url, "_blank");
        },
        deleteFile() {
            if (this.onDelete) {
                this.onDelete(this.file.nameWithDirectory);
            }
        }
    }
};
</script>
<style scoped>
.v-application .text-wrap {
    white-space: normal !important;
    overflow-wrap: anywhere;
}
.v-card__actions {
    padding-top: 0;
}
</style>
